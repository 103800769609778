body {
  font-size: 12px;
}

input {
  color: black;
}

.g4-queue-select {
  height: 40px;
  /* margin-bottom: 5px; */
  border-radius: 3px;
  width: 90%;
  padding: 8px;
  margin: 10px 10px 0px 10px;
}



