body {
  font-size: 12px;
}

input {
  color: black;
}

.cliente {
  background: #2c984a;
  max-width: 255px;
  padding: 8px;
  margin: 5px;
  border-radius: 0px 9px 9px 9px;
  align-self: flex-end;
  color: white;
  box-shadow: 1px;
}

.wait {
  background: #2c984a;
  font-family: Roboto;
  margin: 10px 5px 0 5px;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.atendente {
  background: #dbdbdb;
  max-width: 255px;
  padding: 8px;
  margin: 5px;
  border-radius: 8px 0px 8px 8px;
  align-self: flex-start;
  color: #2f4f4f;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}
