@import url(https://fonts.googleapis.com/css2?family=Roboto:wght@300&display=swap);
@import url(https://stackpath.bootstrapcdn.com/font-awesome/4.7.0/css/font-awesome.min.css);
@import url(https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap);
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  color: black;
}

body {
  font-size: 12px;
}

.g4-logo-open img {
  width: 100px;
}



span {
  font-family: Raleway, sans-serif;
  font-weight: 600;
}

.g4-name-company {
}

body {
  font-size: 12px;
}

input {
  color: black;
}



.g4-inputWithIcon p {
  text-align: left;
  margin-left: 20px;
}


/* .g4-inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 24px;
  color: #aaa;
  transition: 0.3s;
} */

.g4-erros {
  margin-top: 3px;
  font-size: 14px;
  color: red;
}

body {
  font-size: 12px;
}

input {
  color: black;
}

.g4-queue-select {
  height: 40px;
  /* margin-bottom: 5px; */
  border-radius: 3px;
  width: 90%;
  padding: 8px;
  margin: 10px 10px 0px 10px;
}




body {
  font-size: 12px;
}

input {
  color: black;
}

#date_G4_flex {
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  margin-left: 5px;
  font-family: roboto;
}

#text_G4_flex {
  font-size: 15px;
  font-family: Raleway;
  word-wrap: break-word;
  margin-right: 30px;
  white-space: pre-wrap;
}
#date_G4_flex {
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  margin-left: 5px;
  font-family: roboto;
}

#text_G4_flex {
  font-size: 15px;
  font-family: Raleway;
  word-wrap: break-word;
  margin-right: 30px;
}
body {
  font-size: 12px;
}

input {
  color: black;
}

.cliente {
  background: #2c984a;
  max-width: 255px;
  padding: 8px;
  margin: 5px;
  border-radius: 0px 9px 9px 9px;
  align-self: flex-end;
  color: white;
  box-shadow: 1px;
}

.wait {
  background: #2c984a;
  font-family: Roboto;
  margin: 10px 5px 0 5px;
  border-radius: 5px;
  padding: 5px;
  color: #fff;
}

.atendente {
  background: #dbdbdb;
  max-width: 255px;
  padding: 8px;
  margin: 5px;
  border-radius: 8px 0px 8px 8px;
  align-self: flex-start;
  color: #2f4f4f;
}

@-webkit-keyframes blinker {
  50% {
    opacity: 0;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

body {
  font-size: 12px;
}

input {
  color: black;
}

.g4-select-customer-experience {
  margin-top: 10px;
  padding: 5px;
  border-radius: 5px;
}

.g4-select-customer-experience:focus {
  outline: 0;
}

body {
  font-size: 12px;
}

input {
  color: black;
}
