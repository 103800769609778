* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

input {
  color: black;
}

body {
  font-size: 12px;
}

.g4-logo-open img {
  width: 100px;
}
