#date_G4_flex {
  font-size: 10px;
  margin-top: 10px;
  text-align: right;
  margin-left: 5px;
  font-family: roboto;
}

#text_G4_flex {
  font-size: 15px;
  font-family: Raleway;
  word-wrap: break-word;
  margin-right: 30px;
}