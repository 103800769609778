body {
  font-size: 12px;
}

input {
  color: black;
}



.g4-inputWithIcon p {
  text-align: left;
  margin-left: 20px;
}


/* .g4-inputWithIcon i {
  position: absolute;
  left: 0;
  top: 8px;
  padding: 9px 24px;
  color: #aaa;
  transition: 0.3s;
} */

.g4-erros {
  margin-top: 3px;
  font-size: 14px;
  color: red;
}
